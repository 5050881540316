<template lang="html">
  <section class="mx-auto my-16 md:my-24 px-4 text-textBlack lg:px-0 w-full lg:w-1024">
      <h1 class="text-2xl text-pulpBlue font-semibold">Minimum Requirements</h1>
      <div class="w-full mt-0 md:mt-4 flex flex-col md:flex-row flex-wrap justify-between items-start">
          <div class="w-full mt-4 md:mt-0 lg:w-6/12">
              <h2 class="pb-1 text-pulpBlue text-xl font-semibold">neo Study App</h2>
              <h3>Supported devices:</h3>
              <ul class="text-base font-light text-textBlack text-justify">
                <li>iOS 11.0 or later</li>
                <li>Android 4.2 Jelly Bean or later</li>
                <li>Mac OS 11.0 or later with Apple M1 Processor</li>
              </ul>
              <br>
              <h3>Android devices with known issues:</h3>
              <ul class="text-base font-light text-textBlack text-justify">
                  <li>Oppo Smartphones with ColorOS</li>
                  <li>Huawei Honor 9</li>
                  <li>One Plus with Android 9 Pie OS</li>
                  <li>OPPO Reno Ace</li>
                  <li>RedMi Note 7 Pro</li>
                  <li>One plus phone</li>
                  <li>Xiaomi 6</li>
                  <li>Samsung Note 8</li>
              </ul>
              <br>
              <h3>Recommended device:</h3>
              <ul class="text-base font-light text-textBlack text-justify">
                  <li>Android device with 1 GB free RAM available</li>
                  <li>Headphones with microphone (for Speech Recognition activities)</li>
              </ul>
          </div>
          <div class="w-full mt-4 md:mt-0 lg:w-6/12">
              <h2 class="pb-1 text-pulpBlue text-xl font-semibold">neo LIVE</h2>
              <h3>Supported Browsers:</h3>
              <ul class="text-base font-light text-textBlack text-justify">
                  <li>Google Chrome version 72 or later (Windows/MacOS/Android)
                  </li>
                  <li>Mozilla Firefox version 58 or later (Windows/MacOS/Android)
                  </li>
                  <li>Safari version 11 or later (iOS and MacOS)
                  </li>
              </ul>
              <p align='justify'>** Chrome/Firefox on iOS is not supported</p>
              <p align='justify'>** Some browsers with Extension/Add-ons turned on for Adblock/Privacy purpose might block the multimedia functions of neo, please make sure no browser extension/Add-ons are blocking video/audio from our site.</p>
              <br>
              <h3>Hardware Recommendation:</h3>
              <p>Minimum Windows 8 with 2 GB RAM<br>
                Minimum OS X El Capitan with 2 GB RAM<br>
                iOS 11.0 or later <br>
                Android 4.1 Jelly Bean or later<br>
                a dedicated internet connection with a minimum of 1 mbps
                </p>
          </div>
          <div class="w-full mt-4 md:mt-0 lg:w-6/12" style="margin:20px 0 0;">
            <h2 class="pb-1 text-pulpBlue text-xl font-semibold">myneo app</h2>
            <h3>Supported Mobile Devices:</h3>
            <ul class="text-base font-light text-textBlack text-justify">
                <li>
                  iOS 11.0 or later
                </li>
                <li>
                  Android 7.0 and up
                </li>
            </ul>
            <p align='justify' style="width:95%;">** Some browsers with Extension/Add-ons turned on for Adblock/Privacy purpose might block the multimedia functions of neo, please make sure no browser extension/Add-ons are blocking video/audio from our site.</p>
            <br>
            <h3>Recommended device:</h3>
            <ul class="text-base font-light text-textBlack text-justify">
                <li>Android device with 1 GB free RAM available</li>
            </ul>
          </div>
          <div class="w-full mt-4 md:mt-0 lg:w-6/12">
            <h2 class="pb-1 text-pulpBlue text-xl font-semibold">Testing Products</h2>
            <h3>Supported Browsers:</h3>
            <ul class="text-base font-light text-textBlack text-justify">
                <li>
                  Google Chrome version 90 or later (Windows/MacOS/Android)
                </li>
                <li>
                  Mozilla Firefox version 88 or later (Windows/MacOS/Android)
                </li>
                <li>
                  Safari version 13 or later (iOS and MacOS)
                </li>
            </ul>
            <p align='justify'>** Some browsers with Extension/Add-ons turned on for Adblock/Privacy purpose might block the multimedia functions of etest, please make sure no browser extension/Add-ons are blocking video/audio from our site.</p>
          </div>
          <div class="w-full mt-4 md:mt-0 lg:w-6/12" style="margin:20px 0 0;">
            <h2 class="pb-1 text-pulpBlue text-xl font-semibold">neo Study Online</h2>
            <h3>Supported Browsers:</h3>
            <ul class="text-base font-light text-textBlack text-justify">
                <li>
                  Google Chrome version 93 or later (Windows/MacOS/Chromebook)
                </li>
            </ul>
            <h3>Display Recommendation:</h3>
            <ul class="text-base font-light text-textBlack text-justify">
                <li>
                  In Windows operating system, using 100% scale and layout is preferred. You can change it on Settings > Display > Scale and Layout.
                </li>
            </ul>
            <h3>Hardware Recommendation:</h3>
            <ul class="text-base font-light text-textBlack text-justify">
                <li>
                  Minimum Windows 8 with 2 GB RAM
                </li>
                <li>
                  Minimum OS X El Capitan with 2 GB RAM
                </li>
                <li>
                  a dedicated internet connection with a minimum of 1 mbps
                </li>
            </ul>
          </div>
      </div>
  </section>
</template>

<script>
export default {
}
</script>

<style lang="css" scoped>
</style>
